<!--
 * @Author: jiang
 * @Date: 2021-07-12 09:34:19
 * @Description: 流程、要点、制度
-->

<template>
  <el-container
    class="g-page-list"
    style="height: 100%; padding: 0 15px 15px; overflow: hidden;"
  >
    <el-aside
      class="g-bg g-mr-15"
      v-if="userSuperId === userId"
    >
      <el-scrollbar
        y
        view-style="padding: 10px;"
      >
        <dept-tree
          ref="tree"
          @change="onDeptChange"
        ></dept-tree>
      </el-scrollbar>
    </el-aside>
    <el-container>
      <el-header
        class="g-bg main-header"
        height="80px"
      >
        <div class="dept">{{  currentDeptName || userDepartmentName }}</div>
        <date-week-select @change="onWeekChange"></date-week-select>
      </el-header>
      <el-main
        class="g-bg g-mt-15"
        v-loading="loading"
      >
        <section-view title="部门近期工作清单">
          <template
            slot="header"
            v-if="workInfo.id"
          >
            <div style="text-align: right;">
              <el-button
                style="margin-left: auto;"
                type="primary"
                @click="onUpdate"
              >修改</el-button>
            </div>
          </template>
          <el-scrollbar
            y
            view-style="padding: 0 20px"
          >
            <template v-if="workInfo.length === 0 || !workInfo.id">
              <div class="g-empty"></div>
              <el-button
                block
                type="primary"
                @click="onCreateDeptWork"
              >添加</el-button>
            </template>
            <div v-else>
              <div class="section-title">本周总结</div>
                <pre v-html="workInfo.week_summary">
               <!--   <div ></div> -->
                </pre>
              <div class="section-title">下周规划</div>
              <pre v-html="workInfo.next_week_plan">
             <!--    <div v-html="workInfo.next_week_plan"></div> -->
              </pre>
            </div>
          </el-scrollbar>
        </section-view>

        <b-dialog ref="deptWork">
          <work-detail-view
            :default-year="dateYear"
            :default-month="dateMonth"
            :default-index="dateIndex"
            :default-data="rowData"
            :submitLoading="confirmLoading"
            @confirm="onConfirm"
          ></work-detail-view>
        </b-dialog>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import DeptTree from '@/components/tree/dept.vue'
import SectionView from '@/components/section'
import DateWeekSelect from '@/components/select/week/index'
import WorkDetailView from '../components/work-detail.vue'
import { getUserWorkList, getDeptWork, createDeptWork, updateDeptWork } from '@/api/manager'
import { mapState } from 'vuex'

export default {
  components: {
    DeptTree,
    SectionView,
    DateWeekSelect,
    WorkDetailView,
  },
  data() {
    return {
      userSuperId: parseInt(process.env.VUE_APP_SUPER_USER_ID),
      fillList: [],
      list: [],
      currentDept: '',
      currentDeptName: '',
      startDate: '',
      endDate: '',
      loading: false,
      workInfo: {},
      rowData: {},
      confirmLoading: false,
      currentDate: null,
      dateYear: null,
      dateMonth: null,
      dateIndex: null,
    }
  },
  computed: {
    ...mapState('user', {
      userId: state => state.userInfo.id,
      userDepartmentId: state => state.userInfo.department.id,
      userDepartmentName: state => state.userInfo.department.name,
    }),
  },
  methods: {
    fetchList() {
      this.loading = true
      const params = {
        start_date: this.startDate,
        end_date: this.endDate,
      }
      const deptId = this.currentDept || this.userDepartmentId
      getUserWorkList(deptId, params).then(res => {
        this.list = res.not_fill_in
        this.fillList = res.fill_in
      })

      // getDeptWorkList(deptId, params).then(res => {
      //   this.
      // })
      getDeptWork({ ...params, department_id: deptId })
        .then(res => {
          this.workInfo = res
        })
        .finally(() => {
          this.loading = false
        })
    },
    onWeekChange(data) {
      this.startDate = data.start_date
      this.endDate = data.end_date
      // this.dateYear !== data.year && (this.dateYear = data.year)
      // this.dateMonth !== data.month && (this.dateMonth = data.month)
      // this.dateIndex !== data.index && (this.dateIndex = data.index)
      this.dateYear = data.year
      this.dateMonth = data.month
      this.dateIndex = data.index
      this.fetchList()
    },
    onDeptChange(id) {
      this.currentDept = id
      this.$refs.tree.getName(id).then(res => {
        this.currentDeptName = res
      })
      this.fetchList()
    },
    onNav(user = {}) {
      this.$router.push({
        path: '/manager/work-user',
        query: { userId: user.id, userName: user.name, dept: this.currentDeptName },
      })
    },
    onCreateDeptWork() {
      this.rowData = {
        week_summary: '',
        next_week_plan: '',
      }
      this.$refs.deptWork.open()
    },
    onUpdate() {
      this.rowData = {
        id: this.workInfo.id,
        week_summary: this.workInfo.week_summary,
        next_week_plan: this.workInfo.next_week_plan,
      }
      this.$refs.deptWork.open()
    },
    onConfirm(data) {
      if (data.id) {
        updateDeptWork(data.id, data)
          .then(res => {
            this.$message.success('修改成功')
            this.fetchList()
          })
          .finally(() => {
            this.confirmLoading = false
            this.$refs.deptWork.close()
          })
      } else {
        this.confirmLoading = true
        createDeptWork({ ...data, department_id: this.userDepartmentId })
          .then(res => {
            this.$message.success('添加成功')
            this.fetchList()
          })
          .finally(() => {
            this.confirmLoading = false
            this.$refs.deptWork.close()
          })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.main-header {
  display: flex;
  align-items: center;

  .dept {
    margin-right: 20px;
    font-size: 24px;
    font-weight: bold;
    color: #2b2d42;
  }
}

.section-title {
  font-size: 18px;
  font-weight: 600;
  line-height: 50px;
}

.el-main {
  flex: 1;
  min-width: 0;
  overflow: hidden;
}

.work-box {
  display: flex;
  flex-wrap: wrap;

  .work-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 15px;
    border-radius: 5px;

    &:hover {
      cursor: pointer;
      background-color: rgba($color: #000, $alpha: 0.03);
    }

    .name {
      margin-top: 10px;
      font-size: 14px;
      color: #2b2d42;
    }
  }
}
</style>
