<!--
 * @Author: jiang
 * @Date: 2021-07-18 15:08:17
 * @Description: 周选择
-->
<template>
  <div :class="['box', layout]">
    <div class="picker-box">
      <el-date-picker
        style="cursor: pointer;"
        class="month-picker"
        v-model="currentMonth"
        type="month"
        value-format="yyyy-MM"
        :clearable="false"
        placeholder="选择月"
        @change="onMonthChange"
      >
      </el-date-picker>
      <div class='picker-time'>
        {{ currentTime }}
      </div>
    </div>

    <div class="week-list">
      <el-button
        v-for="(item, index) in list"
        :key="index"
        type="primary"
        :plain="index !== current"
        size="small"
        @click="onClick(index)"
      >第{{numMap[index]}}周</el-button>
    </div>
  </div>
</template>

<script>
import { getMonthWeek } from '@/api/report'
export default {
  components: {},
  props: {
    date: {},
    /**
     * 布局形式 vertical {
     * flex-direction: column;} 和 horizontal
     */
    layout: {
      type: String,
      default: 'horizontal',
    },
  },
  data() {
    return {
      numMap: ['一', '二', '三', '四', '五', '六', '七', '八'],
      currentMonth: this.$tools.dayjs().format('YYYY-MM'),
      current: (() => {
        let day = this.$tools.dayjs().day()
        if (day === 0) {
          day = 7
        }
        const date = this.$tools.dayjs().date()
        const res = Math.ceil((date - day) / 7)
        if (res > 0) {
          return res
        }
        return 0
      })(),
      list: [],
    }
  },
  computed: {
    currentTime() {
      const obj = this.list[this.current]
      return obj ? obj.start_date.slice(8) + '日 ~ ' + obj.end_date.slice(8) + '日' : ''
    },
  },
  watch: {
    date: {
      handler(val) {
        console.log(val)
        let yearMonth
        if (val) {
          if (val.month && val.year) {
            yearMonth = `${val.year}-${val.month > 9 ? val.month : '0' + val.month}`
          }
          if (val.index !== undefined) {
            this.current = val.index - 1
          }
        }

        if (this.currentMonth === yearMonth && this.list && this.list.length) return

        if (yearMonth) {
          this.currentMonth = yearMonth
        }

        this.fetchData()
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    fetchData() {
      getMonthWeek(this.currentMonth).then(res => {
        this.list = res
        this.onClick(this.current)
      })
    },
    onMonthChange() {
      this.fetchData()
    },
    onClick(index) {
      this.current = index

      const data = {
        year: this.currentMonth.split('-')[0],
        month: parseInt(this.currentMonth.split('-')[1]),
        index: index + 1,
        ...this.list[this.current],
      }
      this.$emit('change', data)
    },
  },
}
</script>

<style lang="scss" scoped>
.box {
  display: flex;

  .picker-time {
    font-size: 14px;
    color: #555;
  }

  .month-picker {
    width: 150px;

    /deep/ .el-input__inner {
      font-size: 24px;
      background-color: transparent;
      border: none;
    }

    /deep/ .el-input__prefix {
      font-size: 18px;
    }
  }

  &.horizontal {
    align-items: center;

    .picker-time {
      margin-left: 30px;
    }
  }

  &.vertical {
    flex-direction: column;

    .picker-box {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }

    .week-list {
      margin-left: 5px;

      .el-button {
        padding: 8px 13px;
      }
    }
  }
}
</style>
